<template>
  <div class="riskprofiles innerpage">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Manage RiskProfiles</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Edit Risk Profiles</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="dialog = true"
                >
                  + Add Risk Profile
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :disable-sort="true"
                :headers="headers"
                :items="items"
                :search="search"
                :sort-desc="[false, true]"
                multi-sort
                show-select
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
                <template v-slot:item.actions="{ item }">
                  <span class="group">
                    <v-icon class="pa-2" @click="editItem(item)"
                      >mdi-playlist-edit</v-icon
                    >
                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
    <v-dialog v-model="dialog" width="1000">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>{{ formTitle }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    filled
                    v-model="editedItem.profile_name"
                    label="Name"
                    :rules="[v => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.alert_type"
                    label="Alert type"
                    :value="editedItem.alert_type"
                    :rules="[v => !!v || 'Alert Type is required']"
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.min_score"
                    label="Min score"
                    :value="editedItem.min_score"
                    :rules="[v => !!v || 'Min score is required']"
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.max_score"
                    label="Max score"
                    :value="editedItem.max_score"
                    :rules="[v => !!v || 'Max score is required']"
                  ></v-text-field>
                  <v-select
                    v-model="editedItem.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Categories"
                    outlined
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="back-to-blue mb-3 ml-5"
              rounded
              @click="dialog = false"
            >
              cancel
            </v-btn>
            <v-btn
              class="mb-3 mr-3 ms-auto"
              color="secondary"
              rounded
              @click="saveItem(editedItem)"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "../services/courses.service";
import { Role } from "@/helpers/roles";

export default {
  name: "ManageRiskProfiles",
  components: {
    Header
  },
  data() {
    return {
      dialog: false,
      search: "",
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Manage Risk Profiles",
          disabled: true,
          href: "/riskprofiles"
        }
      ],
      editedItem: {
        profile_name: "",
        alert_type: "",
        min_score: null,
        max_score: null,
        custom: true,
        organization_id: null,
        category_id: null
      },
      originalItem: {
        profile_name: "",
        alert_type: "",
        min_score: null,
        max_score: null,
        custom: true,
        organization_id: null,
        category_id: null
      },
      defaultlItem: {
        profile_name: "",
        alert_type: "",
        min_score: null,
        max_score: null,
        custom: true,
        organization_id: null,
        category_id: null
      },
      items: [],
      categories: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      headers: [
        { text: "Name", value: "profile_name" },
        { text: "Type", value: "alert_type" },
        { text: "Min score", value: "min_score" },
        { text: "Max score", value: "max_score" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  computed: {
    orgId() {
      return this.currentUser.user.organizations.id;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    formTitle: {
      get() {
        return this.editedIndex === -1
          ? "Create New Risk Profile"
          : "Edit Risk Profile";
      },
      set(newTitle) {
        return newTitle;
      }
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getItems();
      this.setCategories();
    },

    async getItems() {
      this.dataLoading = true;

      const response = await CoursesService.getRiskProfiles();

      this.items = response.data.data.slice();
      this.dataLoading = false;
    },

    async setCategories() {
      this.dataLoading = true;

      const response = await CoursesService.getCategories();

      this.categories = response.data.data.slice();
      this.dataLoading = false;
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this risk profile?") &&
        this.items.splice(index, 1);

      await CoursesService.removeRiskProfile({
        id: item.id
      });

      this.showSnackbar(item.profile_name + " has been deleted");
    },

    editItem(item) {
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      let data = {};
      if (isValid) {
        if (this.editedIndex > -1) {
          if (this.isAdminEditor) {
            // update item
            data = {
              profile_name: item.profile_name,
              alert_type: item.alert_type,
              min_score: item.min_score,
              max_score: item.max_score,
              category_id: item.category_id
            };
          } else {
            // update item
            data = {
              profile_name: item.profile_name,
              alert_type: item.alert_type,
              min_score: item.min_score,
              max_score: item.max_score,
              custom: true,
              organization_id: this.orgId,
              category_id: item.category_id
            };
          }

          const response = await CoursesService.updateRiskProfile({
            id: item.id,
            data: data
          });
          this.items.splice(this.editedIndex, 1, response.data);
          this.showSnackbar("The Risk Profile has been updated successfully");
        } else {
          // create item
          if (this.isAdminEditor) {
            data = {
              profile_name: item.profile_name,
              alert_type: item.alert_type,
              min_score: item.min_score,
              max_score: item.max_score,
              category_id: item.category_id
            };
          } else {
            data = {
              profile_name: item.profile_name,
              alert_type: item.alert_type,
              min_score: item.min_score,
              max_score: item.max_score,
              custom: true,
              organization_id: this.orgId,
              category_id: item.category_id
            };
          }

          const response = await CoursesService.createRiskProfile({
            data: data
          });
          this.items.push(response.data);
          this.showSnackbar("The Risk Profile has been added successfully");
        }
      }
      this.dialog = false;
      this.$refs.form.reset();
    }
  }
};
</script>
<style lang="scss" scoped>
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
</style>
